import React, { Fragment } from 'react'

function App() {
  return (
    <Fragment>
      <div className="fullwidth clearfix">
        <div id="topcontainer" className="bodycontainer clearfix" data-uk-scrollspy="{cls:'uk-animation-fade', delay: 300, repeat: true}">
          
          <p><img src="assets/images/logo.png" alt="" style={{ width: '50%', marginBottom: '15px' }} /></p>
          <h2>Coming soon</h2>          
        </div>
      </div>

      <div className="arrow-separator arrow-white"></div>

      <div className="fullwidth colour1 clearfix">
        <div id="countdown" className="bodycontainer clearfix" data-uk-scrollspy="{cls:'uk-animation-fade', delay: 300, repeat: true}">

          <div id="countdowncont" className="clearfix">
            <ul id="countscript">
              <li>
                <span className="days">00</span>
                <p>Days</p>
              </li>
              <li>
                <span className="hours">00</span>
                <p>Hours</p>
              </li>
              <li className="clearbox">
                <span className="minutes">00</span>
                <p>Minutes</p>
              </li>
              <li>
                <span className="seconds">00</span>
                <p>Seconds</p>
              </li>
            </ul>
          </div>
        
        </div>
      </div>

      <div className="arrow-separator arrow-theme"></div>

      <div className="fullwidth clearfix">
        <div id="footercont" className="bodycontainer clearfix" data-uk-scrollspy="{cls:'uk-animation-fade', delay: 300, repeat: true}">

          <p className="backtotop"><a title="" href="#backtotop"><span className="fa fa-angle-double-up"></span></a></p>
          <div id="socialmedia" className="clearfix">
            <ul>
              <li><a title="" href="https://www.facebook.com/uberousinc" rel="external"><span className="fa fa-facebook"></span></a></li>
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default App;
